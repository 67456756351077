import React from "react";

const Privacy = () => {
  return (
    <div className="h-full bg-black flex flex-col gap-8 p-4 md:p-16 landing">
      <div className="flex gap-4 flex-col">
        <h2 className="text-white text-xl">Privacy policy</h2>
        <span className="text-[#545454]">
          Interverse Solutions Limited Privacy Policy <br />
          Last Updated: 28th January 2022
        </span>
      </div>
      <div className="flex gap-4 flex-col">
        <h2 className="text-white text-xl">OVERVIEW</h2>
        <span className="text-[#545454]">
          This privacy policy (this “Policy”) explains how Personal Information
          is collected, used, and disclosed by Interverse Solutions
          Limited (collectively, ” Interverse Solutions,” ” we,” ” our” or
          ” us”). This Policy applies to users (“you”) of Interverse Solutions’
          services, websites, applications, and machines (each, a ” Service,”
          and collectively, our ” Services”). <br />
          <br />
          By using our Services, to the extent permitted by applicable law, you
          are consenting to us gathering and processing information about you in
          accordance with this Policy, our Cookies Policy, and any other
          contract we may have with you including, without limitation, a
          Merchant Services Agreement. Although consent may not be required for
          all of the elements described in this Policy. For particular types of
          information or processing, we may provide you with choices or request
          your further consent related to what information we collect and how we
          may process it. If you do not agree to the terms set out in these
          policies, we do not give you permission to use the Services and you
          must cease to do so immediately.
        </span>
      </div>
      <div className="flex gap-4 flex-col">
        <h2 className="text-white text-xl">1.  INFORMATION WE COLLECT</h2>
        <span className="text-[#545454]">
          Personal Information. By using our Services, we may collect the
          following categories of personal information (“personal information”)
          of the person(s) who are company director(s), beneficial owner(s),
          or authorised person(s) of the merchant that we provide Services to:{" "}
          <br />
          <br />
          <ul className="list-disc px-6">
            <li>Name</li>
            <li>Date of birth</li>
            <li>Place of birth and nationality</li>
            <li>
              Identification documentation, such as copy of the passport,
              National Identity card or such other documentation as is required
              by local law, copies of these documents may include a photograph
              of your face
            </li>
            <li>Home address, email address and other contact details</li>
          </ul>
          <br />
          <br />
          We may ask for additional information such as, but not limited to,
          financial standing, employment details and experience to support
          merchant account application. We will also use our own records and
          information from other sources where applicable
          <br />
          <br />
          We processes your Personal Information to meet our legal, statutory
          and contractual obligations and to provide you with our Services. We
          will never collect any unnecessary Personal Information from you and
          do not process your information in any way, other than as specified in
          this Policy.
          <br />
          <br />
          Under certain circumstances, it may be necessary for you to provide us
          with your Personal Information, for example, to enable us to provide
          Services to you or to comply with our legal obligations. In other
          circumstances, it will be at your discretion whether you provide us
          with Personal Information or not. However, failure to supply any of
          the Personal Information we request may mean that we are unable to
          maintain or provide Services to you.
          <br />
          <br />
          We make every effort to maintain the accuracy and completeness of your
          Personal Information which we store and to ensure all of your Personal
          Information is up to date. However, you can assist us with this by
          promptly contacting us if there are any changes to your Personal
          Information. We shall not be responsible for any losses you suffer
          arising from any inaccurate, inauthentic, deficient or incomplete
          Personal Information that you provide to us.
        </span>
      </div>
      <div className="flex gap-4 flex-col">
        <h2 className="text-white text-xl">2. HOW WE USE YOUR INFORMATION</h2>
        <span className="text-[#545454]">
          We only process your Personal Information where:
          <br />
          <br />
          1. the processing is necessary to comply with our legal obligations
          including:
          <ul className="list-disc px-6">
            <li>
              to carry out money laundering, financial and credit checks and for
              fraud and crime prevention and detection purposes
            </li>
            <li>to comply with our legal and regulatory obligations; and</li>
            <li>
              to comply with court orders and exercise and/or defend our legal
              rights, as otherwise permitted or required by any applicable law
              or regulation; or
            </li>
          </ul>
          <br />
          <br />
          2. the processing is necessary for the performance of a contract to
          which you are party, or in order to take steps at your request prior
          to entering into such contract; or
          <br />
          <br />
          3. the processing is necessary for our legitimate interests or the
          legitimate interests of others (except where such interests are
          overridden by the interests or rights of your employees, agents and
          contractors and those of your associated entities requiring the
          protection of their Personal Information) including:
          <ul className="list-disc px-6">
            <li>
              for monitoring and assessing compliance with Interverse Solutions
              policies and standards;
            </li>
            <li>for promotional and marketing materials and activities</li>
            <li>
              to comply with court orders and exercise and/or defend our legal
              rights, as otherwise permitted or required by any applicable law
              or regulation;
            </li>
            <li>
              to identify person(s) authorised to act on behalf of our clients
            </li>
            <li>
              for administrative purposes in relation to the security and access
              of our systems, offices, platforms and secure websites and
              applications; and
            </li>
            <li>to contact you about the Services we offer</li>
          </ul>
          <br />
          <br />
          Our Role as Data Controller and Data Processor. We generally act as
          the data controller of your Personal Information. Interverse Solutions
          do not and will not share, disclose or trade your Personal Information
          collected from above processes. Under certain payment scheme, payment
          companies may engage us to provide them with certain processing
          services related to information owned or controlled by the payment
          companies. We are not responsible for any payment companies’ use of
          information for which it is an owner or controller.
          <br />
          <br />
          Retention. We will only retain your Personal Information for as long
          as necessary to fulfill the purpose for which it was collected or to
          comply with legal, regulatory or internal policy requirements.
          However, if you wish to have your Personal Information removed from
          our databases, you can make such a request by writing to the address
          detailed in Contact Us section. Subject to any legal or regulatory
          requirements, we will then delete this information (to the extent
          technically practicable)
        </span>
      </div>
      <div className="flex gap-4 flex-col">
        <h2 className="text-white text-xl">3. SECURITY OF YOUR INFORMATION</h2>
        <span className="text-[#545454]">
          Interverse Solutions takes commercially-reasonable technical,
          administrative, and physical security measures designed to protect
          your information from loss, misuse, unauthorised access, disclosure,
          alteration, and destruction.
          <br />
          <br />
          When your merchant transaction is being transmitted to our Services or
          through our Services, it will be protected by cryptographic protocols.
          To be clear, Interverse Solutions does not itself store any Personal
          Information of yours or your clients’ through transactions. We assume
          no liability or responsibility for disclosure of any personal
          information due to errors in transmission, unauthorised third-party
          access, or other causes beyond our control.
          <br />
          <br />
          You play an important role in keeping your information secure. You
          should not share your user name, password, or other security
          information for your Interverse Solutions account with anyone. If we
          receive instructions using your user name and password, we will
          consider that you have authorised the instructions. If you have reason
          to believe that your interaction with us is no longer secure (e.g., if
          you feel that the security of any account you might have with us has
          been compromised), please contact us immediately as detailed in the
          Contact Us section, below.
        </span>
      </div>
      <div className="flex gap-4 flex-col">
        <h2 className="text-white text-xl">  4. COOKIES</h2>
        <span className="text-[#545454]">
          We collect information about your usage and activity on our website
          using certain technologies, such as cookies, and other technologies. 
        </span>
      </div>
      <div className="flex gap-4 flex-col">
        <h2 className="text-white text-xl">5. YOUR CHOICES</h2>
        <span className="text-[#545454]">
          Email. If you do not want to receive marketing and promotional emails
          from Interverse Solutions, you may click on the “unsubscribe” link in
          the email to unsubscribe and opt-out of marketing email
          communications. If you have an InterverseSolutions account, you may
          also opt-out of marketing emails in your account settings.
          Alternatively, to the extent that applicable law requires your prior
          opt-in consent to receive marketing and promotional emails, you can
          choose not to opt-in. Please note that even if you opt-out of
          receiving marketing communications from one or all of our Services, we
          may need to send you service-related communications.
          <br />
          <br />
          Text Messages. If you no longer want to receive text messages from
          Interverse Solutions, please let us know to opt-out of text messages
          at support@interversesolutions.com  or write to us, as detailed in
          Contact Us section. Cookies Choices. To exercise choices regarding
          cookies set through our website, as well as other types of online
          tracking and internet advertising, see our Cookies Policy for more
          details.
          <br />
          <br />
          In addition to the above methods of exercising choice, to the extent
          required by applicable law, we may provide you with additional choices
          regarding the processing of your information, which you may exercise
          by contacting us as detailed in the Contact Us section, below. We will
          try to comply with your request as soon as reasonably practicable as
          required by applicable law. 
        </span>
      </div>
      <div className="flex gap-4 flex-col">
        <h2 className="text-white text-xl">
          6. ACCESS, CORRECTION AND DELETION OF YOUR INFORMATION
        </h2>
        <span className="text-[#545454]">
          If you wish to update, modify/correct, delete or, access your Personal
          Information, receive a copy of the personal data collected from you,
          or if you would like us to stop processing any of your Personal
          Information, to the extent you are entitled to do so under applicable
          law, you can make such a request by writing to us detailed in the
          Contact Us section, below. We will respond to your request within the
          time prescribed by applicable law. If such changes are made pursuant
          to your request(s) this may affect our ability to continue to provide
          our Services to you and under such circumstances, we reserve the right
          to terminate our Services to you. In any of the situations listed
          above, in order for us to comply with our security obligations and to
          prevent unauthorised disclosure of data, we may request that you
          provide us with a copy of a valid means of identification and
          verification of the identity of the individual whose Personal
          Information is the subject of such a request.
        </span>
      </div>
      <div className="flex gap-4 flex-col">
        <h2 className="text-white text-xl">
           7. WHEN WE MAY DISCLOSE YOUR PERSONAL DATA
        </h2>
        <span className="text-[#545454]">
          Interserve Solutions do not and will not share, disclose or trade your
          Personal Information. We will only disclose your Personal Information
          in the ways set out in this Policy and in the following circumstances:
          <br />
          <br />
          <ul className="list-disc px-6">
            <li>
              To third parties who process your Personal Information on our
              behalf (such as our money laundering, financial and sanction check
              providers);
            </li>
            <li>To regulatory bodies, or there is a legal requirement.</li>
          </ul>
        </span>
      </div>
      <div className="flex gap-4 flex-col">
        <h2 className="text-white text-xl"> 8. INTERNATIONAL TRANSFERS</h2>
        <span className="text-[#545454]">
          Information about you may be transferred to, or accessed by, entities
          located around the world as described in this Policy. Some of these
          entities may be located in countries that do not provide an equivalent
          level of protection as your home country. Where required, Interverse
          Solutions will ensure that we have adequate safeguards in place so
          that your Personal Information is treated securely and in accordance
          with applicable law and regulation and with our policies and
          standards. By using our Services, and providing us information about
          you, you consent to the international transfer of information about
          you to the above parties. The most common reason for us disclosing
          information overseas is because we use service providers to perform
          some functions on our behalf, and some of these service providers are
          located outside the European Economic Area (EEA). We only disclose
          information to these organisations when it is necessary for the
          services they provide to us. For the most part, these service
          providers are entities related to us which perform a range of
          technology and operational functions for us.
        </span>
      </div>
      <div className="flex gap-4 flex-col">
        <h2 className="text-white text-xl"> 9. CHANGES TO THIS POLICY</h2>
        <span className="text-[#545454]">
          Except to the extent limited by applicable law, we reserve the right
          to update this Policy to reflect changes to our information practices
          by prominently posting notice of the update on our Services, and as
          required, obtaining your consent. Any updates will become effective
          immediately after posting the updates to this Policy and apply to all
          information collected about you, or where required, upon your consent.
          You agree that you will review this Policy periodically. If we make
          any changes to this Policy, we will change the “Last Updated” date
          above. You are free to decide whether or not to accept a modified
          version of this Policy, but accepting this Policy, as modified, is
          required for you to continue using our Services. If you do not agree
          to the terms of this Policy or any modified version of this Policy,
          your sole recourse is to terminate your use of our Services. If we
          make any changes to this Policy that materially impacts previously
          collected information about you, we will obtain your prior express
          consent.
        </span>
      </div>
      <div className="flex gap-4 flex-col">
        <h2 className="text-white text-xl"> 10. CONTACT US</h2>
        <span className="text-[#545454]">
          If you have any questions about this Policy or information we have
          collected about you, please contact us by email at
          support@interversesolutions.com or by postal mail at:
        </span>
      </div>
    </div>
  );
};

export default Privacy;
